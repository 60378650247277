import initFlippingTiles from './inc/_flipping-tile';

export default function initLinkListWidget(): void {
    const linklistWrapper = document.querySelector<HTMLDivElement>('[data-linklist-widget-wrapper]');
    if (linklistWrapper === null) {
        return;
    }

    initFlippingTiles(linklistWrapper);
}
