import Chart from 'chart.js';
import selector from '../../../../../../design/1/js/app/components/tableSelectors';
import ZaFaTable from '../../../../../../design/1/js/app/components/zaFaTable';
import PdfPrinting from '../../../../../../design/1/js/app/helpers/pdfPrinting';
import Storage from '../../../../../../design/1/js/app/helpers/localStorage';

export default function initFlippingTiles(linklistWidgetWrapper: HTMLDivElement): void {
    const anchorList = linklistWidgetWrapper.querySelectorAll<HTMLAnchorElement>('[data-flipping-tile-anchor]');
    const diagramWrapperList = linklistWidgetWrapper.querySelectorAll<HTMLDivElement>('[data-diagram-wrapper]');

    for (const anchor of Array.from(anchorList)) {
        const tileWrapper = anchor.closest('.plf-dashboard-tile-wrapper') as HTMLDivElement;
        const diagramWrapper = tileWrapper.querySelector<HTMLDivElement>('[data-diagram-wrapper]');
        const dashboardTile = tileWrapper.querySelector<HTMLDivElement>('[data-dashboard-tile]');

        anchor.addEventListener('click', () => {
            diagramWrapper.style.width = `${dashboardTile.offsetWidth}px`;
            diagramWrapper.style.height = `${dashboardTile.offsetHeight}px`;
        });

        window.addEventListener('DOMContentLoaded', () => resizeDashboardTile(dashboardTile, tileWrapper));
        window.addEventListener('load', () => resizeDashboardTile(dashboardTile, tileWrapper));
        window.addEventListener('resize', () => resizeDashboardTile(dashboardTile, tileWrapper));
        const observer = new MutationObserver(() => resizeDashboardTile(dashboardTile, tileWrapper));
        observer.observe(tileWrapper, { attributes: true, childList: true, subtree: true });
    }

    for (const diagramWrapper of Array.from(diagramWrapperList)) {
        initTileDiagram(diagramWrapper);
    }

    const tooltipWrapperList = document.querySelectorAll<HTMLDivElement>('[data-tooltip]');
    tooltipWrapperList.forEach(wrapper => {
        wrapper.addEventListener('click', event => {
            event.stopPropagation();
        });
    });
}

function resizeDashboardTile(tile: HTMLDivElement, tileWrapper: HTMLDivElement): void {
    if (!tile || !tile.offsetHeight) return;

    const tileFront = tileWrapper.querySelector<HTMLDivElement>('.flip-tile-front');
    const tileBack = tileWrapper.querySelector<HTMLDivElement>('.flip-tile-back');

    const windowWidth = window.innerWidth;

    if (windowWidth <= 1024) {
        tileWrapper.style.height = `${tile.offsetHeight + 8}px`;
        tileWrapper.style.width = '100%';

        if (tileFront && tileBack) {
            tileFront.style.height = `${tile.offsetHeight}px`;
            tileFront.style.width = '100%';
            tileBack.style.height = `${tile.offsetHeight}px`;
            tileBack.style.width = '100%';
        }
    } else {
        tileWrapper.style.height = `${tile.offsetHeight + 16}px`;
        tileWrapper.style.width = '';

        if (tileFront && tileBack) {
            tileFront.style.height = `${tile.offsetHeight}px`;
            tileFront.style.width = '';
            tileBack.style.height = `${tile.offsetHeight}px`;
            tileBack.style.width = '';
        }
    }

    const innerTile = tile.parentElement?.parentElement?.parentElement;
    const tileBackDiagramWrapper = innerTile?.querySelector<HTMLDivElement>('.plf-diagram-wrapper');

    if (tileBackDiagramWrapper) {
        if (tileBackDiagramWrapper.offsetHeight !== tile.offsetHeight) {
            tileBackDiagramWrapper.style.height = `${tile.offsetHeight}px`;
            tileBackDiagramWrapper.style.width = `${tile.offsetWidth}px`;
        }
    }
}

function initTileDiagram(diagramWrapper: HTMLDivElement): void {
    const chartType = diagramWrapper.querySelector('canvas')?.getAttribute('data-chart-type') ?? 'bar';

    const canvasSelector = chartType === 'line' ? selector.LINE_CHART : selector.BAR_CHART;
    const canvas = diagramWrapper.querySelector<HTMLCanvasElement>(canvasSelector);

    if (canvas === null) {
        return;
    }

    const PdfPrinter = new PdfPrinting();
    const TableService = new ZaFaTable(Storage, PdfPrinter);

    const dataset = TableService.addLabelsToDatasetByColIds(
        JSON.parse(canvas.dataset.chart),
        JSON.parse(canvas.dataset.colIds),
    );
    const steps = parseInt(canvas.dataset.steps, 10);
    const maxY = parseInt(canvas.dataset.max, 10);
    const minY = parseInt(canvas.dataset.min, 10);
    const context = canvas.getContext('2d');
    const labelStr = diagramWrapper.dataset.unit;

    // Convert String array to number array
    dataset.datasets[0].data = dataset.datasets[0].data.map(Number);

    console.log(dataset); // eslint-disable-line

    // eslint-disable-next-line
    new Chart(
        context,
        TableService.generateSBBChartConfig({
            type: chartType,
            dataset,
            labelStr,
            maxY,
            minY,
            steps,
        }),
    );
}
